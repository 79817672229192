html, body, p, div, h1, h2, h3, h4, h5, h6, ul, ol, dl, img, pre, form, fieldset {
  margin: 0;
  padding: 0;
}

img, fieldset {
  border: 0;
}

body, html {
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--ds-surface, #fff);
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: var(--ds-font-size-100, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ds-font-lineHeight-200, 1.42857);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-decoration-skip-ink: auto;
}

p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {
  margin: var(--ds-space-150, 12px) 0 0 0;
}

a {
  color: var(--ds-link, #0052cc);
  text-decoration: none;
}

a:hover {
  color: var(--ds-link, #0065ff);
  text-decoration: underline;
}

a:active {
  color: var(--ds-link-pressed, #0747a6);
}

a:focus-visible {
  outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #2684ff);
  outline-offset: var(--ds-space-025, 2px);
}

@supports not selector(*:focus-visible) {
  a:focus {
    outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #4c9aff);
    outline-offset: var(--ds-space-025, 2px);
  }
}

h1 {
  font-size: 2.07143em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.10345;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.01em;
  margin-top: 40px;
}

h2 {
  font-size: 1.71429em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.16667;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.01em;
  margin-top: 40px;
}

h3 {
  font-size: 1.42857em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.2;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.008em;
  margin-top: 28px;
}

h4 {
  font-size: 1.14286em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.25;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.006em;
  margin-top: 24px;
}

h5 {
  font-size: 1em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.14286;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.003em;
  margin-top: 16px;
}

h6 {
  font-size: .857143em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.33333;
  font-weight: var(--ds-font-weight-semibold, 600);
  text-transform: uppercase;
  margin-top: 20px;
}

ul, ol, dl {
  padding-left: var(--ds-space-500, 40px);
}

dd, dd + dt, li + li, ul ul:not(:first-child), ol ul:not(:first-child), ul ol:not(:first-child), ol ol:not(:first-child) {
  margin-top: var(--ds-space-050, 4px);
}

p:first-child, ul:first-child, ol:first-child, dl:first-child, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, blockquote:first-child, pre:first-child, form:first-child, table:first-child {
  margin-top: 0;
}

blockquote, q {
  color: inherit;
}

blockquote {
  padding-left: var(--ds-space-500, 40px);
  border: none;
}

[dir="rtl"] blockquote {
  padding-left: 0;
  padding-right: var(--ds-space-500, 40px);
}

blockquote:before, q:before {
  content: "“";
}

blockquote:after, q:after {
  content: "”";
}

blockquote:before {
  float: left;
  text-align: right;
  width: 1em;
  margin-left: -1em;
}

[dir="rtl"] blockquote:before {
  float: right;
  text-align: left;
  margin-right: -1em;
}

blockquote > :last-child {
  display: inline-block;
}

small {
  font-size: .785714em;
  font-style: inherit;
  color: var(--ds-text-subtlest, var(--ds-text-subtlest, #6b778c));
  line-height: 1.45455;
  font-weight: var(--ds-font-weight-bold, 700);
  margin-top: 16px;
  font-weight: normal;
}

code, kbd {
  font-family: SFMono-Medium, SF Mono, Segoe UI Mono, Roboto Mono, Ubuntu Mono, Menlo, Consolas, Courier, monospace;
}

var, address, dfn, cite {
  font-style: italic;
}

abbr {
  border-bottom: 1px var(--ds-border, #ccc) dotted;
  cursor: help;
}

@supports (color-scheme: dark) and (color-scheme: light) {
  [data-color-mode="light"] {
    color-scheme: light;
  }

  [data-color-mode="dark"] {
    color-scheme: dark;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead, tbody, tfoot {
  border-bottom: 2px solid var(--ds-border, #dfe1e6);
}

td, th {
  padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
  text-align: left;
  border: none;
}

th {
  vertical-align: top;
}

td:first-child, th:first-child {
  padding-left: 0;
}

td:last-child, th:last-child {
  padding-right: 0;
}

caption {
  font-size: 1.42857em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.2;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.008em;
  margin-top: 28px;
  margin-bottom: var(--ds-space-100, 8px);
  text-align: left;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

@-moz-document url-prefix() {
  img {
    font-size: 0;
  }

  img:-moz-broken {
    font-size: inherit;
  }
}

button {
  font-family: inherit;
}

.assistive {
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}
/*# sourceMappingURL=index.afcd2e76.css.map */
